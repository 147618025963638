<template>
  <DSidebarView title="Account" color="blue" :items="subNavigation" />
</template>

<script setup>
import { CollectionIcon, UserCircleIcon } from '@heroicons/vue/solid'
import { DiscordIcon } from '@ui'

import DSidebarView from '@/components/ui/layout/DSidebarView.vue'

const subNavigation = [
  {
    name: 'Profile',
    description: 'Manage your display name, email address and account settings.',
    to: { name: 'Account' },
    exact: true,
    icon: UserCircleIcon
  },
  {
    name: 'Discord',
    description: 'Connect your Discord account for notifications & giveaway entries.',
    to: { name: 'LinkDiscord' },
    icon: DiscordIcon
  },
  {
    name: 'Wallets',
    description: 'Connect new wallets to your account.',
    to: { name: 'LinkWallet' },
    icon: CollectionIcon
  },
]
</script>